import { getDoc, onSnapshot, query } from "firebase/firestore";
import { competitionsCollection, getEventDocRef } from "./firebase";
import useEvent from "./useEvent";

const { useState, useEffect } = require("react");

function useCompetitions(reload, queryConstraints) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [competitions, setCompetitions] = useState([])
    const [events, setEvents] = useState([])

    useEffect(() => {
        if (!reload) { 
            setEmpty()
            return
        }
        setLoading(true)
        const q = query(competitionsCollection, ...queryConstraints)
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            if (querySnapshot && querySnapshot.docs.length > 0) {
                const comps = []
                querySnapshot.docs.forEach(d => {
                    const c = d.data()
                    c.id = d.id
                    comps.push(c)
                })
                comps.sort((a, b) => {
                    if (a.date_id < b.date_id) {
                        return 1
                    } else if (b.date_id < a.date_id) {
                        return -1
                    } else {
                        return 0
                    }
                })
                loadEvents(comps)
            } else {
                setEmpty()
            }

        })
        return () => unsubscribe()
    }, [reload])

    function setEmpty() {
        setCompetitions([])
        setEvents([])
        setLoading(false)
    }

    async function loadEvents(sortedComps) {
        const dateIDs = sortedComps.map(c => { return c.date_id })
        let uniqueValues = [...new Set(dateIDs)]
        const promises = uniqueValues.map(v => { return getEvent(v) })
        const evs = await Promise.all(promises)
        setCompetitions(sortedComps)
        setEvents(evs)
        setLoading(false)
    }

    async function getEvent(date_id) {
        const ref = getEventDocRef(date_id)
        const docSnap = await getDoc(ref)
        const e = docSnap.data()
        e.id = docSnap.id
        return e
    }

    function competitionEvents() {
        const compEvents = []
        competitions.forEach(c => {
            const dict = {}
            dict.competition = c
            dict.event = events.find(e => { return e.id === c.date_id })
            compEvents.push(dict)
        })
        return compEvents
    }

    return {
        error,
        loading,
        competitions,
        competitionEvents
    }
}

export default useCompetitions