import { Link } from "react-router-dom"

function CompletedContestCard({ competitionEventInfo }) {
    return (
        <Link className='bg-white rounded-md shadow-md h-fit p-2 flex flex-col space-y-1' to={`/tournaments/${competitionEventInfo.competition?.date_id}/competitions/${competitionEventInfo.competition?.id}`}>
            
                <label className="text-sm font-semibold">{competitionEventInfo.event?.name + ' | ' + competitionEventInfo.competition?.name}</label>
                <label className="text-sm">{competitionEventInfo.event?.getDateRangeText()}</label>
        </Link>
    )
}

export default CompletedContestCard