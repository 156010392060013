import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "./BasicButton";
import Input from "./Input";
import { useState } from "react";
import { createCompetition, logFirebaseEvent, useSession } from "../firebase/firebase";
import useEvent from "../firebase/useEvent";
import LoadingComponent from "./LoadingComponent";

function CreateCompetitionPage({ openAuthModal }) {
    const user = useSession()
    const params = useParams()
    const eventLoader = useEvent(params.date_id)
    const [saving, setSaving] = useState(false)

    const [name, setName] = useState("")
    const navigate = useNavigate()

    async function handleSubmit() {
        console.log('handle submit, userid: ', user?.uid)
        if (!user) {
            openAuthModal()
        } else if (!name || name === "") {
            alert('Please enter a name for your contest')
        } else {
            setSaving(true)
            const date_id = eventLoader.event?.startDate + "_" + eventLoader.event?.pga_permID
            logFirebaseEvent('competition_created', null)
            const competitionid = await createCompetition(name, date_id, user.uid)
            navigate(`/tournaments/${date_id}/competitions/${competitionid}`)
        }
    }

    if (eventLoader.loading || saving) {
        return <LoadingComponent />
    } else {
        return (
            <div className="w-full flex flex-col space-y-2 items-center p-2">
                <div className="w-full max-w-xl text-md font-bold">{eventLoader.event?.name}</div>
                <div className="w-full max-w-xl">
                    <Input eyebrow="" placeholder="New Contest Name" type="text" onChange={(e) => setName(e.target.value)} />
                    <button className="mt-4 px-4 py-2 bg-darkGreen hover:bg-gray-400 text-white font-bold rounded-md" onClick={handleSubmit}>Create Contest</button>
                </div>
            </div>
        )
    }
    
}

export default CreateCompetitionPage;