class Competition {
    constructor(name, date_id, createdAt, createdBy, entries, global) {
        this.name = name
        this.date_id = date_id
        this.createdAt = createdAt
        this.createdBy = createdBy
        this.entries = entries
        this.global = global
    }

    getScoredEntry(entry, eventLoader) {
        const players = entry.picks.map((p, idx) => {
            const fullName = p.firstName + " " + p.lastName
            const dict = {}
            dict.id = p.tournyPlayerID_PGA
            dict.fullName = fullName
            dict.lastName = p.lastName
            const score = eventLoader.playerScores[fullName]
            dict.scoreText = score?.text ?? "-"
            dict.scoreValue = score?.value ?? 0
            dict.thru = score?.thru ?? "-"
            dict.teeTime = score?.teeTime
            dict.todayScoreText = score?.todayScoreText
            return dict
        })
        players.sort((a, b) => {
            if (a.scoreValue < b.scoreValue) {
                return -1
            } else if (a.scoreValue > b.scoreValue) {
                return 1
            } else {
                return 0
            }
        })
        entry.players = players
        let tot = 0
        for (let i = 0; i < entry.players.length; i++) {
            if (i > 3) { break }
            tot += entry.players[i].scoreValue
        }
        entry.score = tot
        return entry
    }

    getScoredEntries(eventLoader) {
        const scoredEntries = this.entries.map((entry, idx) => {
            return this.getScoredEntry(entry, eventLoader)
        })
        scoredEntries.sort((a, b) => {
            if (a.score < b.score) {
                return -1
            } else if (a.score > b.score) {
                return 1
            } else {
                return 0
            }
        })
        for (let i = 0; i < scoredEntries.length; i++) {
            const entry = scoredEntries[i]
            if (i > 0 && entry.score === scoredEntries[i - 1].score) {
                entry.rank = scoredEntries[i - 1].rank
            } else {
                entry.rank = i + 1
            }
        }
        return scoredEntries
    }
}

const competitionConverter = {
    toFirestore: (competition) => {
        return {
            name: competition.name,
            date_id: competition.date_id,
            createdAt: competition.createdAt,
            createdBy: competition.createdBy,
            entries: competition.entries,
            global: competition.global
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options)
        return new Competition(data.name, data.date_id, data.createdAt, data.createdBy, data.entries, data.global ?? false)
    }
}

export { Competition, competitionConverter }