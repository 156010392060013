import { useState } from "react"

function InviteLinkComponent() {

    const [copied, setCopied] = useState(false)

    async function copyToClipboard() {
        try {
            navigator.clipboard.writeText(window.location.href)
            setCopied(true)
        } catch (err) {
            alert("Uh oh! There was an issue copying the link. Try copying the url directly from your browser window instead.")
            setCopied(false)
        }
        
    }

    return (
        <div className="bg-white shadow rounded-md p-3 flex flex-col">
            <label className="text-md font-bold">Invite Link</label>
            <label className="text-sm font-regular">Others can join the contest by following the link below and creating a new entry</label>
            <div className="flex flex-row h-8 space-x-2 items-center mt-2">
                <label className="text-gray-500 bg-gray-200 h-full pt-1 pl-2 pr-1 rounded flex-grow truncate self-stretch">{window.location.href}</label>
                <button className="w-fit whitespace-nowrap pl-3 pr-3 border-2 border-darkGreen text-sm font-bold text-darkGreen h-full rounded" onClick={() => copyToClipboard()}>
                    { copied ? 'Copied!':'Copy Link' }
                </button>
            </div>
        </div>
    )
}

export default InviteLinkComponent