import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from "../firebase/firebase";
import useCompetition from "../firebase/useCompetition";
import useEvent from "../firebase/useEvent";
import CompetitionHeader from "./CompetitionHeader";
import InviteLinkComponent from "./InviteLinkComponent";
import LoadingComponent from "./LoadingComponent";
import TeamPlayerScores from "./TeamPlayerScores";
import arrow from "../assets/fwd_crt_white.png";

function CompetitionPage() {
    const user = useSession()
    const params = useParams()
    const competitionLoader = useCompetition(params.id)
    const eventLoader = useEvent(params.date_id)
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState([])

    useEffect(() => {
        // fetchScores()
    }, [])

    function newEntryClicked() {
        navigate(`/tournaments/${params.date_id}/competitions/${params.id}/new-entry`)
    }

    function editClicked(entry) {
        navigate(`/tournaments/${params.date_id}/competitions/${params.id}/new-entry?entryCreator=${entry.createdBy}&entryDate=${entry.createdAt.toDate().getTime()}`)
    }

    function emailGroupMailToString() {
        let emails = []
        competitionLoader.competition.entries.forEach(e => {
            const email = competitionLoader.idEmailMap[e.createdBy]
            if (!emails.includes(email) && email !== user?.email) {
                emails.push(email)
            }
        })
        return `mailto:${emails.join(',')}?subject=${encodeURIComponent(eventLoader.event.name + " | " + competitionLoader.competition.name)}`
    }

    function entryClicked(entryIndex) {
        console.log('entry clicked: ', entryIndex)
        const scoredEntries = competitionLoader.competition?.getScoredEntries(eventLoader)
        const entry = scoredEntries[entryIndex]
        if (eventLoader.tournyStillUpcoming() && entry.createdBy === user?.uid) { return }
        // if (!eventLoader.tournyStillUpcoming()) {

        const newExpanded = []
        for (let i = 0; i < competitionLoader.competition?.entries.length; i++) {
            if (i === entryIndex) {
                newExpanded.push(!expanded[i])
            } else {
                newExpanded.push(expanded[i])
            }
        }
        setExpanded(newExpanded)
        // }
    }

    if (competitionLoader.loading || eventLoader.loading) {
        return (<LoadingComponent />)
    } else {
        const scoredEntries = competitionLoader.competition?.getScoredEntries(eventLoader)
        if (expanded.length === 0 && scoredEntries.length > 0) {
            setExpanded(scoredEntries.map((entry, idx) => {
                return (user?.uid === entry.createdBy)
            }))
        }

        return (
            <div className="w-full flex flex-col items-center bg-gray-100 overflow-auto">
                <div className={`w-full max-w-xl ${eventLoader.tournyStillUpcoming() ? 'pl-2 pr-2' : ''}`}>
                    {/* <div className={`${eventLoader.tournyStillUpcoming() ? '':'ml-2 mr-2'}`}> */}
                    <div className={`${eventLoader.tournyStillUpcoming() ? '' : 'px-2'} w-full`}>
                        <CompetitionHeader eventName={eventLoader.event?.name} competitionName={competitionLoader.competition?.name} statusText={eventLoader.tournyStillUpcoming() ? '' : eventLoader.eventStatusText} asOfText={eventLoader.tournyStillUpcoming() ? '' : eventLoader.event?.lastUpdatedText()} />
                    </div>
                    <div className={`flex flex-col space-y-3 mb-3 ${eventLoader.tournyStillUpcoming() ? '' : 'hidden'}`}>
                        <button className="w-full bg-darkGreen hover:bg-gray-400 px-4 py-2 rounded-md shadow text-white text-left font-bold flex flex-row justify-between items-center" onClick={newEntryClicked}>
                            {"Create New Entry"}
                            <img className="h-4" src={arrow} />
                        </button>
                        <InviteLinkComponent />
                    </div>

                    <div className={`${eventLoader.tournyStillUpcoming() ? 'space-y-2' : ''}`}>
                        <label className={`text-sm font-medium ${eventLoader.tournyStillUpcoming() ? '' : 'ml-2'} ${scoredEntries.length > 0 ? '' : 'hidden'}`}>{`Total entries: ${scoredEntries.length}`}</label>
                        {
                            scoredEntries.map((entry, idx) => {
                                return <TeamPlayerScores index={idx} key={entry.name} entry={entry} tournamentNotStarted={eventLoader.tournyStillUpcoming()} userEntered={user?.uid === entry.createdBy} editClicked={editClicked} expanded={expanded[idx]} handleClick={entryClicked} email={competitionLoader.idEmailMap[entry.createdBy]} roundInProgress={eventLoader.roundInProgress()} />
                            })
                        }
                        <label className={`flex text-sm font-medium ${eventLoader.tournyStillUpcoming() ? '' : 'hidden'}`}>Leaderboard available once the tournament begins</label>
                    </div>

                    <div className="flex w-full p-2">
                        <a href={emailGroupMailToString()} className="flex w-full rounded-md font-bold text-md text-white p-2 bg-darkGreen">Email The Group</a>
                    </div>
                </div>
            </div>
        )
    }

}

export default CompetitionPage;