import { useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import useEvent from "../firebase/useEvent"

function TournamentPage() {
    const params = useParams()
    const eventLoader = useEvent(params.date_id)

    useEffect(() => {
        console.log('date_id: ', params.date_id)
    }, [])

    if (!eventLoader.loading && !eventLoader.event) {
        return (<div>Tournament not available</div>)
    } else {
        return (
            <div className="w-full flex flex-col space-y-2 mt-2 mb-2 items-center">
                <Link className="w-full max-w-xl text-blue-600" to={`/tournaments/${params.date_id}/create`}>Create Game</Link>
                <div className="w-full max-w-xl">{eventLoader.event?.name}</div>
            </div>
        )
    }
}

export default TournamentPage