import { useState } from "react"
import Input from "../components/Input";
import BasicButton from "../components/BasicButton";
import { useNavigate } from "react-router-dom";
import { createAccount, logIn, connectWithGoogle, logFirebaseEvent } from "../firebase/firebase";
import googleIcon from "../assets/google_g.png"
import arrow from "../assets/fwd_crt_black.png"

function AuthOverlay({ show, closeAuthModal }) {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [authType, setAuthType] = useState(0)
    const navigate = useNavigate()

    function handleSubmit() {
        console.log('handle submit')
        if (email === "") {
            alert("Oops! You forgot to enter your email address.")
        } else if (password === "") {
            alert("Oops! you forgot to enter a password.")
        } else if (authType === 0) {
            console.log('create account')
            createAccount(email, password, closeAuthModal)
        } else {
            console.log('log in')
            logIn(email, password, closeAuthModal)
        }
        return false
    }

    function continueWithGoogle() {
        connectWithGoogle(closeAuthModal)
    }

    function toggleContext() {
        setAuthType(authType === 0 ? 1 : 0)
    }

    function SwitchAuthTypeComponent() {
        if (authType === 0) {
            return (
                <label className="text-sm font-medium">Already have an account? <button onClick={toggleContext} className="text-blue-600 font-bold">Login</button></label>
            )
        } else {
            return (
                <label className="text-sm font-medium">Don't have an account? <button onClick={toggleContext} className="text-blue-600 font-bold">Sign Up</button></label>
            )
        }
    }

    if (show) {
        logFirebaseEvent('auth_modal_shown', null)
    }

    return (
        <div className={`bg-black bg-opacity-70 flex flex-col items-center w-screen h-screen z-40 fixed ${show ? '' : 'hidden'}`}>
            <div className="bg-white w-full max-w-lg h-fit m-auto rounded-md shadow flex flex-col items-center space-y-3 p-5">
                <div className="w-full flex flex-row justify-between">
                    <div className="text-lg font-bold">{authType === 0 ? 'Sign Up' : 'Log In'}</div>
                    <button onClick={closeAuthModal} className="text-md font-regular">close</button>
                </div>
                <button className="flex flex-row justify-between items-center px-3 w-full h-10 border shadow rounded-md" onClick={continueWithGoogle}>
                    <div className="flex flex-row">
                        <img className="h-6 mr-3" src={googleIcon} />
                        <label className="text-medium font-medium">Continue with Google</label>
                    </div>
                    <img className="h-4" src={arrow} />
                </button>
                <div className="w-full flex flex-row items-center">
                    <div className="h-px flex-grow bg-gray-400"/>
                    <label className="mx-3 text-xs font-semibold text-gray-600">OR</label>
                    <div className="h-px flex-grow bg-gray-400"/>
                </div>
                <form className="w-full flex flex-col space-y-3">
                    <Input className='w-full' eyebrow="Email" placeholder="email address" type="email" onChange={(e) => setEmail(e.target.value)} />
                    <Input className='w-full' eyebrow="Password" placeholder="password" type="password" onChange={(e) => setPassword(e.target.value)} />
                </form>
                <button onClick={handleSubmit} className="w-full bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md">{authType === 0 ? 'Create Account' : 'Log In'}</button>

                <SwitchAuthTypeComponent />
            </div>
        </div>
    )
}

export default AuthOverlay