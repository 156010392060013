import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { logFirebaseEvent, updateCompetition, useSession } from "../firebase/firebase"
import useCompetition from "../firebase/useCompetition"
import useEvent from "../firebase/useEvent"
import TournamentGrouping from "./TournamentGrouping"
import CompetitionHeader from "./CompetitionHeader"
import ClipLoader from "react-spinners/ClipLoader"
import { getPlayerScores } from "../data/api"
import LoadingComponent from "./LoadingComponent"

function CreateCompetitionEntryPage({ openAuthModal }) {
    const user = useSession()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const competitionLoader = useCompetition(params.id)
    const eventLoader = useEvent(params.date_id)
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [tournamentStatus, setTournamentStatus] = useState("")

    const [existingEntry, setExistingEntry] = useState(null)
    const [selectedPlayers, setSelectedPlayers] = useState([null, null, null, null, null, null])
    const [entryName, setEntryName] = useState("")
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        updateCompetitionStatus()
    }, [])

    async function updateCompetitionStatus() {
        setLoadingStatus(true)
        const response = await getPlayerScores(params.date_id, handleFetchScoresError)
        setTournamentStatus(response.statusName)
        setLoadingStatus(false)
    }

    function handleFetchScoresError(err) {
        setTournamentStatus("")
        setLoadingStatus(false)
    }

    async function submitEntry() {
        if (selectedPlayers.includes(null)) {
            alert("Oops! You forgot to select a player from each group.")
        } else if (entryName === "") {
            alert("Uh oh! We need a team name then we're good to go")
        } else if (!user) {
            openAuthModal()
        } else if (!submitting) {
            // alert("ready to submit")
            setSubmitting(true)
            const response = await getPlayerScores(params.date_id).catch(handleFetchScoresError)
            if (response.statusName !== 'STATUS_SCHEDULED') {
                alert('This tournament has already begun and is now locked')       
                setSubmitting(false)
                return
            }
            
            const now = new Date()
            const newEntry = {}
            newEntry.createdBy = user.uid
            newEntry.name = entryName
            newEntry.picks = selectedPlayers
            if (existingEntry) {
                competitionLoader.competition.entries = competitionLoader.competition?.entries.filter((e) => {
                    return (e.createdBy !== existingEntry.createdBy || e.createdAt.toDate().getTime().toString() !== existingEntry.createdAt.toDate().getTime().toString())
                })
                newEntry.createdAt = existingEntry.createdAt
                newEntry.updatedAt = now
            } else {
                newEntry.createdAt = now
            }
            
            competitionLoader.competition?.entries.push(newEntry)
            logFirebaseEvent('entry_created', null)
            const res = await updateCompetition(competitionLoader.competition)
            setSubmitting(false)
            navigate(`/tournaments/${params.date_id}/competitions/${params.id}`)
            // alert('successfully submitted')
        }
    }

    function handlePlayerSelection(player) {
        // console.log('selected player: ', player.firstName + " " + player.lastName, " grouping: ", player.grouping)
        const ps = selectedPlayers.map((p, idx) => {
            if (idx === player.grouping - 1) {
                if (p !== null && p.tournyPlayerID_PGA === player.tournyPlayerID_PGA) {
                    // deselecting
                    return null
                } else {
                    return player
                }
            } else {
                return p
            }
        })
        setSelectedPlayers(ps)
        // ps[player.grouping - 1] = player
        // console.log('selected players array: ', selectedPlayers.map((p, idx) => { 
        //     if (p === null) {
        //         return "-"
        //     } else {
        //         return p.lastName
        //     }
        //  }))
        // setSelectedPlayers([...selectedPlayers, ...ps])
    }

    if (!eventLoader.loading && !eventLoader.event) {
        return (<div>Tournament not available</div>)
    } else if (!competitionLoader.loading && !competitionLoader.competition) {
        return (<div>Competition not available</div>)
    } else if (eventLoader.loading || competitionLoader.loading || loadingStatus || submitting) {
        return <LoadingComponent />
    } else if (tournamentStatus !== 'STATUS_SCHEDULED') {
    // } else if (true) {
        return (<div className="w-full flex flex-col items-center bg-gray-100">
            <label>This tournament has begun and is now locked</label>
        </div>)
    } else if (searchParams.get('entryCreator') && searchParams.get('entryDate') && !existingEntry) {
        // setExistingEntry(competitionLoader.competition?.entries[0])
        // console.log('entry date: ', searchParams.get('entryDate'))
        const entry = competitionLoader.competition?.entries.find((e, idx) => {
            return (e.createdBy === searchParams.get('entryCreator') && e.createdAt.toDate().getTime().toString() === searchParams.get('entryDate'))
        })
        if (!entry) {
            navigate(`/tournaments/${params.date_id}/competitions/${params.id}/new-entry`)
            // setSelectedPlayers([])
            // setExistingEntry(1)
        } else {
            setSelectedPlayers(entry.picks)
            setEntryName(entry.name)
            setExistingEntry(entry)
        }
        return (<></>)
    } else {
        return (
            <div className="w-full flex flex-col items-center bg-gray-100">
                {/* <div className="w-screen h-screen z-10"><ClipLoader color='blue' loading={submitting} /></div> */}

                <div className="w-full max-w-xl flex flex-col space-y-2 p-2">
                    <CompetitionHeader eventName={eventLoader.event?.name} competitionName={competitionLoader.competition?.name} />
                    <div className="mt-2 p-2 h-fit bg-gray-200 rounded-md text-sm">Select a player from each of the six groups below to create your roster</div>

                    {
                        eventLoader.event?.getGroupedPlayers().map((g, idx) => {
                            return <TournamentGrouping key={idx} players={g} selections={selectedPlayers} handlePlayerSelection={handlePlayerSelection} />
                        })
                    }
                    <div className="h-px w-full bg-gray-400" />
                    <label className="text-xs font-bold">TEAM NAME</label>
                    <input className="shadow appearance-none border-2 border-gray-700 h-11 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Enter team name" onChange={(e) => setEntryName(e.target.value)} defaultValue={existingEntry?.name} />
                    <button className="w-full max-w-xl bg-darkGreen rounded-md h-11 text-white font-semibold" onClick={submitEntry}>{existingEntry ? 'Update Entry' : 'Submit Entry'}</button>
                </div>
            </div>
        )
    }
}

export default CreateCompetitionEntryPage