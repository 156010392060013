import selectedCheck from '../assets/noun-select-198791.png'

function PlayerButton({ onClick, player, selected }) {
    return (
        <button type="button" onClick={onClick} className={`bg-white ${selected ? 'border-2 border-darkGreen' : 'border border-gray-200'}  text-black text-left font-medium rounded-md shadow-md flex items-center h-11`}>
            <img
                className="w-14 h-full object-contain"
                // src={`https://a.espncdn.com/combiner/i?img=/i/headshots/golf/players/full/${player.id_espn}.png&w=224&scale=crop&cquality=40`} 
                src={`https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_2.0,f_auto,g_face:center,h_190,q_auto,w_190/headshots_${player.tournyPlayerID_PGA}.png`}
                onError={({ currentTarget }) => {
                    // currentTarget.onerror = null; // prevents looping
                    // currentTarget.hidden='true'
                    // currentTarget.src=null;
                }} />
            <div className='w-full p-2 flex flex-row justify-between'>
                <label>{player.firstName + " " + player.lastName}</label>
                <img className={`h-6 ${selected ? '':'hidden'}`} src={selectedCheck} />
            </div>



        </button>
    )
}

export default PlayerButton;