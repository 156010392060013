import logo from './logo.svg';
import { Route, Routes, useNavigate } from 'react-router-dom'
import NavBar from './components/NavBar';
import PlayerList from './components/PlayerList';
import { useAuth, userContext, logout } from './firebase/firebase';
import Welcome from './components/Welcome';
import Dashboard from './components/Dashboard';
import Tournaments from './components/Tournaments';
import TournamentPage from './components/TournamentPage';
import CreateCompetitionPage from './components/CreateCompetitionPage';
import CompetitionPage from './components/CompetitionPage';
import CreateCompetitionEntryPage from './components/CreateCompetitionEntryPage';
import AdminPage from './components/AdminPage';
import LoadingComponent from './components/LoadingComponent';
import { useState } from 'react';
import AuthOverlay from './components/AuthOverlay';
import AdminAnalytics from './components/AdminAnalytics';

function App() {
  const { initializing, user } = useAuth()
  const navigate = useNavigate()
  const [authTypeIndex, setAuthTypeIndex] = useState(0)
  const [authOverlayOpen, setAuthOverlayOpen] = useState(!initializing && !user)

  function accountClicked() {
    console.log('account clicked...')
    if (user) {
      if (window.confirm("Sign Out?")) {
        logout()
      }
    } else {
      setAuthOverlayOpen(true)
    }
    // } else if (window.location.pathname === '/signup') {
    //   setAuthTypeIndex(authTypeIndex === 0 ? 1:0)
    // } else {
    //   navigate('/signup')
    // }
  }

  function openAuthModal() {
    setAuthTypeIndex(0)
    setAuthOverlayOpen(true)
  }

  function closeAuthModal() {
    setAuthTypeIndex(0)  
    setAuthOverlayOpen(false)
  }

  if (initializing) {
    return (<LoadingComponent />)
  // } else if (!user) {
  //   return (<Welcome />)
  } else {
    return (
      <userContext.Provider value={{ user }}>
        <div className="bg-gray-100 h-screen">
          <AuthOverlay show={authOverlayOpen} closeAuthModal={closeAuthModal}/>
          <NavBar user={user} handleAccountClick={accountClicked} rightButtonText={window.location.pathname === '/signup' ? (authTypeIndex === 0 ? 'Login':'Signup'):null} className='hidden'/>
          <Routes>
            {/* <Route path="/" element={<PlayerList />} /> */}
            <Route path="*" element={<Dashboard />} />
            {/* <Route path='/signup' element={<Welcome authTypeIndex={authTypeIndex}/>} /> */}
            <Route path='/view' element={<AdminPage />} />
            <Route path='/tournaments/:date_id' element={<TournamentPage />} />
            <Route path='/tournaments/:date_id/create' element={<CreateCompetitionPage openAuthModal={openAuthModal} />} />
            <Route path='/tournaments/:date_id/competitions/:id' element={<CompetitionPage />} />
            <Route path='/tournaments/:date_id/competitions/:id/new-entry' element={<CreateCompetitionEntryPage openAuthModal={openAuthModal}/>} />
            <Route path='/adminanalytics' element={<AdminAnalytics />} />
          </Routes>
          {/* <div className="flex absolute bottom-2 right-2 z-50 px-4 py-2 bg-darkGreen text-white text-sm font-bold sticky w-20 rounded">Contact Us</div> */}
        </div>
      </userContext.Provider>
    );
  }

}

export default App;
