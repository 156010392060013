import { Link } from "react-router-dom"
import lightLogo from "../assets/teeboxlogo_white.svg"

function NavBar({ user, handleAccountClick, rightButtonText }) {

    function RightButton() {
        if (rightButtonText !== null) {
            return (<button className={`text-white text-right font-semibold`} onClick={handleAccountClick}>
                {rightButtonText}
            </button>)
        } else if (user === null) {
            return (
                <button className={`text-white text-right text-sm font-semibold whitespace-nowrap`} onClick={handleAccountClick}>
                    Sign up / Log in
                </button>
            )
        } else {
            return (
                <button className={`text-white text-right`} onClick={handleAccountClick}>
                    <div className={`text-sm font-medium`}>My Account</div>
                    <div className="text-xs">{user?.email ?? ""}</div>
                </button>
            )
        }
    }

    return (
        // <div>
        <nav className="flex flex-nowrap justify-center bg-darkGreen p-2 sticky top-0 z-30 shadow">
            <div className="w-full max-w-xl flex flex-row justify-center">
                <div className="flex flex-row space-x-2 w-full max-w-xl items-center">
                    <Link to='/'>
                        <div className="flex items-center">
                            <img className="h-8 mr-1" src={lightLogo} />
                            <label className="ml-1 w-40 text-white font-brand font-bold text-lg">Tee Box Fantasy</label>
                        </div>
                    </Link>
                    {/* <Link to='/tournaments'>
                            <div className="text-white">Tournaments</div>
                        </Link> */}
                </div>
                <RightButton />
            </div>
        </nav>
        // </div>
    )
}

export default NavBar