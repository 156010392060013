import { where } from "firebase/firestore"
import { useSession } from "../firebase/firebase";
import useCompetitions from "../firebase/useCompetitions";
import useCompetition from "../firebase/useCompetition";

function AdminAnalytics() {

    const user = useSession()

    const myCompetitionsLoader = useCompetitions("-", [where('date_id', '==', "20240613_R2024026")])
    const competionLoaders = {
        "ckYa90XMfta5Ngg3Mc8k" : useCompetition("ckYa90XMfta5Ngg3Mc8k"),
        "dtxACiJgsNdlFmiCsb4j" : useCompetition("dtxACiJgsNdlFmiCsb4j")
    } 

    const mastersButNotUSOpenPlayers = [
        'Fred Couples',
        'Santiago De la Fuente',
        'Matt Fitzpatrick',
        'Ryo Hisatsune',
        'Lee Hodges',
        'Zach Johnson',
        'Christo Lamprecht',
        'Luke List',
        'Grayson Murray',
        'Joaquin Niemann',
        'José María Olazábal',
        'Thorbjørn Olesen',
        'Jon Rahm',
        'Patrick Reed',
        'Charl Schwartzel',
        'Vijay Singh',
        'Jasper Stubbs',
        'Camilo Villegas',
        'Bubba Watson',
        'Mike Weir',
        'Danny Willett'
    ]

    function affectedEntries() {
        const misentries = []
        myCompetitionsLoader.competitions.forEach(c => {
            if (c.entries) {
                c.entries.forEach(e => {
                    const missingPeople = e.picks.filter(p => {
                        return mastersButNotUSOpenPlayers.includes(p.fullName)
                    }).map(p => p.fullName)
                    if (missingPeople.length > 0) {
                        misentries.push({ cid: c.id, competitionName: c.name, email: competionLoaders[c.id]?.idEmailMap[e.createdBy], name: e.name, creatorID: e.createdBy, missing: missingPeople })
                    }
                })
            }
        })

        misentries.forEach(e => {
            console.log(e.email, ": ", e.missing.join(', '))
            // return { email: e.email, picks: e.missing }
        })

        return misentries
    }

    function picks() {
        let picks = {}
        myCompetitionsLoader.competitions.forEach(c => {
            if (c.entries) {
                c.entries.forEach(e => {
                    e.picks.forEach(p => {
                        const name = p.fullName
                        if (picks[name]) {
                            picks[name] += 1
                        } else {
                            picks[name] = 1
                        }
                    })
                })
            }
        })
        // return picks

        let picksArray = []
        for (const p in picks) {
            if (mastersButNotUSOpenPlayers.includes(p)) {
                picksArray.push({ name: p, count: picks[p] })    
            }
        }
        picksArray.sort((a, b) => { return (a.count < b.count) ? 1 : (a.count > b.count) ? -1 : 0 })
        return picksArray
    }

    if (user?.email !== 'leland.j.fidler@gmail.com') {
        return <></>
    }

    if (myCompetitionsLoader.loading) {
        return <>loading...</>
    }

    return (
        <>
            {/* {myCompetitionsLoader.competitionEvents().length + " competitions loaded..."} */}
            {/* {
                picks().map((p, idx) => {
                    return <div key={idx}>{p.name + ": " + p.count}</div>
                })
            } */}
            {
                affectedEntries().map((e, idx) => {
                    return (
                        <div key={idx}>
                            <label>{e.cid + " | " + e.competitionName + " | " + e.name + " | " + e.email + ": " + e.missing.join(', ')}</label>
                        </div>
                    )
                })
            }
        </>
    )
}

export default AdminAnalytics;