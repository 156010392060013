class Event {
    constructor(pga_permID, name, field, startDate, endDate, heroImage, currentJson, scoresLastUpdated) {
        this.pga_permID = pga_permID
        this.name = name
        this.field = field.sort((a, b) => {
            if (a.grouping < b.grouping) {
                return -1
            } else if (a.grouping > b.grouping) {
                return 1
            } else {
                return 0
            }
        })
        this.startDate = startDate
        this.endDate = endDate
        this.heroImage = heroImage
        this.currentJson = currentJson
        this.scoresLastUpdated = scoresLastUpdated
    }

    getDateRangeText() {
        const start = this.getDate(this.startDate)
        const end = this.getDate(this.endDate)
        const startText = start.toLocaleDateString("en-US", { month: 'short', day: 'numeric' })
        const endText = end.toLocaleDateString("en-US", { month: 'short', day: 'numeric' })
        return startText + " - " + endText
        // return start.toString()
    }

    lastUpdatedText() {
        if (this.scoresLastUpdated) {
            if (this.currentJson?.statusName === 'STATUS_FINAL') {
                return ""
            } else {
                const date = this.scoresLastUpdated.toDate()
                const text = date.toLocaleTimeString("en-US", { hour: 'numeric', minute: '2-digit' })
                return 'scores as of: ' + text
            }
        } else {
            return ""
        }
    }

    getDate(dateString) {
        const year = dateString.substring(0, 4)
        const month = dateString.substring(4, 6)
        const day = dateString.substring(6, 8)
        const date = new Date(year + "/" + month + "/" + day)
        return date
    }

    getGroupedPlayers() {
        if (this.field && this.field.length > 0) {
            let groups = []
            for (let i = 0; i < this.field.length; i++) {
                const p = this.field[i]
                if (groups.length < p.grouping) {
                    groups.push([p])
                } else {
                    groups[p.grouping - 1].push(p)
                }
            }
            groups.forEach(g => {
                g.sort((a, b) => {
                    if (a.lastName < b.lastName) {
                        return -1
                    } else {
                        return 1
                    }
                })
            })
            return groups
        } else {
            return []
        }
    }
}

const eventConverter = {
    toFirestore: (event) => {
        return {
            pga_permID: event.pga_permID,
            name: event.name,
            field: event.field,
            startDate: event.startDate,
            endDate: event.endDate,
            heroImage: event.heroImage,
            currentJson: event.currentJson,
            scoresLastUpdated: event.scoresLastUpdated
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options)
        return new Event(data.pga_permID, data.name, data.field, data.startDate, data.endDate, data.heroImage, data.currentJson, data.scoresLastUpdated)
    }
}

export { Event, eventConverter }