import arrow from "../assets/fwd_crt_black.png"

function TeamPlayerScores({ index, entry, tournamentNotStarted, userEntered, editClicked, expanded, handleClick, email, roundInProgress }) {

    function PlayerSummary({ player, activated }) {
        return (
            <div className={`h-9 flex flex-row justify-between items-center ${activated ? 'opacity-100' : 'opacity-80'}`}>
                <div className="flex flex-row items-center h-full">
                    <img className="h-full ml-8 mr-2 object-contain" src={`https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_2.0,f_auto,g_face:center,h_190,q_auto,w_190/headshots_${player.id}.png`} />
                    <label className="font-medium min-w-full">{player.fullName}</label>
                </div>
                <div className={`flex flex-row h-full items-center ${tournamentNotStarted ? 'hidden' : ''}`}>
                    <label className={`text-md text-right font-bold ${roundInProgress ? '' : 'mr-3'}`}>{player.scoreText}</label>
                    <label className={`mr-2 w-14 text-xs text-right font-light ${roundInProgress ? '' : 'hidden'}`}>{getPlayerStatusText(player)}</label>
                    {/* <label>{getTeeTimeText(player.teeTime)}</label> */}
                </div>
            </div>
        )
    }

    function getPlayerStatusText(player) {
        if (isNaN(player.thru)) {
            return "-"
        } else if (player.thru === 0) {
            return getTeeTimeText(player.teeTime)
        } else if (player.todayScoreText) {
            return `${player.todayScoreText} (${getThruText(player.thru)})`
        } else {
            return ""
        }
    }

    function getTeeTimeText(teeTime) {
        if (!teeTime) {
            return ""
        } else {
            const date = new Date(teeTime)
            return date.toLocaleTimeString("en-US", { hour: 'numeric', minute: '2-digit' }).replaceAll(" ", "")
        }
    }

    function getThruText(thruValue) {
        if (isNaN(thruValue)) {
            return "-"
        } else if (thruValue === 18) {
            return "F"
        } else if (thruValue === 0) {
            return ""
        } else {
            return thruValue
        }
    }

    function PlayersArray() {
        if (tournamentNotStarted && !userEntered) {
            return <></>
        } else if (!expanded) {
            return (
                <div className="w-full p-2 bg-white flex flex-row justify-between">
                    <label className="ml-8 font-medium mr-2 text-sm">{entry.players.map((p, idx) => {
                        return (p.lastName)
                    }).join(', ')}</label>
                    <img className="h-4 rotate-90 self-center mr-2" src={arrow} />
                </div>)
        } else {
            return (<>
                {
                    entry.players.map((p, idx) => {
                        return (
                            <div key={p.id}>
                                <div className={`w-full h-px bg-black ${idx === 4 && !tournamentNotStarted ? 'visible' : 'invisible'}`} />
                                <PlayerSummary player={p} activated={idx < 4 || tournamentNotStarted} />
                            </div>)
                    })
                }
            </>)
        }
    }

    // if (tournamentNotStarted && !userEntered) {
    //     return (
    //         <div className="w-full h-fit bg-white rounded-md shadow flex flex-col">
    //             <div className="w-full h-fit bg-darkBlue rounded-t-md flex">
    //                 <label className="font-semibold px-2 py-1 text-white">{entry.name}</label>
    //             </div>
    //             <label className="text-sm font-regular text-black px-2 py-1">{email}</label>
    //         </div>
    //     )
    // } else {
    return (
        <div className={`w-full h-fit bg-white ${tournamentNotStarted && (userEntered || expanded) ? 'border border-darkBlue rounded-md' : ''}`} onClick={() => handleClick(index)}>
            <div className={`w-full h-fit bg-darkBlue ${tournamentNotStarted ? (userEntered || expanded ? 'rounded-t':'rounded-md') : ''} text-white flex flex-row justify-between items-center`}>
                <div className="flex flex-row py-1.5">
                    <label className={`w-10 text-center ${tournamentNotStarted ? 'hidden' : ''}`}>{entry.rank}</label>
                    <label className={`font-semibold ${tournamentNotStarted ? 'pl-2' : ''}`}>{entry.name}</label>
                </div>
                <div className={`h-full py-1.5 w-10 bg-lightBlue text-white font-bold flex flex-row items-center justify-center ${tournamentNotStarted ? 'hidden' : ''}`}>{entry.score === 0 ? "E" : (entry.score > 0 ? `+${entry.score}` : entry.score)}</div>
            </div>
            <div className="flex flex-col w-full">
                <label className={`w-full ${ tournamentNotStarted && !userEntered ? 'pl-2':'bg-gray-200 pl-10' }  pt-1 pb-1 text-sm ${expanded ? '' : 'hidden'}`}>{email}</label>
                {
                    PlayersArray()
                }
                <button onClick={() => editClicked(entry)} className={`m-2 bg-gray-200 text-black font-bold py-2 rounded-md ${tournamentNotStarted && userEntered ? '' : 'hidden'}`}>Make Changes</button>
            </div>
        </div>
    )
    // }
}

export default TeamPlayerScores