import arrow from "../assets/fwd_crt_black.png"
import reloadCircle from "../assets/noun-reload-1121898.png"

function CompetitionHeader({ competitionName, eventName, statusText, asOfText }) {
    return (
        <div className="flex flex-row py-1 items-center justify-between w-full">
            {/* <img className="h-4 rotate-180 mr-3 ml-2" src={arrow} /> */}
            <div className="flex flex-col w-full">
                <div>
                    <label className="font-bold text-black text-md">{eventName} </label>
                    <label className="font-regular text-gray-600 text-md whitespace-nowrap">{competitionName}</label>
                </div>
                {/* <div className="flex flex-row"> */}
                {/* <label className="font-bold text-lg">{eventName}</label> */}
                {/* <div className="w-0.5 h-4 bg-black ml-2 mr-2 self-center" /> */}
                {/* <label className="font-semibold text-lg">{competitionName}</label> */}
                {/* </div> */}
                <div className="flex flex row justify-between items-center">
                    <label className="text-sm font-semibold text-gray-600">{statusText}</label>
                    <label className="text-xs italic text-gray-600">{asOfText}</label>
                </div>

            </div>
            {/* <img className={`h-8 mr-0.5 bg-white shadow p-2 rounded-full hover:bg-gray-300 ${ reloadData ? '':'hidden'}`} src={reloadCircle} onClick={reloadData} /> */}
        </div>
    )
}

export default CompetitionHeader