import { getUserEmailMapping } from "../data/api.js";
// const { onSnapshot } = require("firebase/firestore");
import { onSnapshot } from "firebase/firestore";
const { useEffect, useState } = require("react");
const { getCompetitionDocRef } = require("./firebase");

function useCompetition(id) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [competition, setCompetition] = useState(null)
    const [idEmailMap, setIDEmailMap] = useState({})

    useEffect(() => {
        const competitionDocRef = getCompetitionDocRef(id)
        const unsubscribe = onSnapshot(competitionDocRef, (d) => {
            if (d.exists()) {
                const e = d.data()
                e.id = d.id
                setCompetition(e)
            } else {
                setCompetition(null)
            }
            updateIDEmailMap()
        })
        return () => unsubscribe()
    }, [id])

    async function updateIDEmailMap() {
        const mapping = await getUserEmailMapping(id)
        setIDEmailMap(mapping)
        setLoading(false)
    }

    

    return {
        error,
        loading,
        competition,
        idEmailMap
    }
}

export default useCompetition