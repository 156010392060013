import { useState } from "react"
import PlayerButton from "./PlayerButton"

function TournamentGrouping({ players, selections, handlePlayerSelection }) {

    // const [selectedPlayer, setSelectedPlayer] = useState(null)

    // function handlePlayerSelection(p) {
    //     // setSelectedPlayer(null)
    //     if (selectedPlayer === null) {
    //         setSelectedPlayer(p)
    //     } else {
    //         setSelectedPlayer(null)
    //     }
    // }

    const grouping = players[0].grouping
    const selectedPlayer = selections[grouping - 1]
    // console.log('grouping: ', grouping, 'selectedplayer: ', selectedPlayer?.lastName)

    function PlayersArray() {
        if (selectedPlayer !== null) {
            return (<PlayerButton key={selectedPlayer.tournyPlayerID_PGA} player={selectedPlayer} selected={true} onClick={() => handlePlayerSelection(selectedPlayer)}/>)
        } else {
            return (
                <>
                    {
                        players.map((p, idx) => {
                            return (<PlayerButton key={p.tournyPlayerID_PGA} player={p} selected={false} onClick={() => handlePlayerSelection(p)}/>)
                        })
                    }
                </>
            )
        }
    }

    return (
        <div className="flex flex-col w-full max-w-xl space-y-1.5">
            <label className="text-xs font-bold">GROUP { grouping }</label>
            <PlayersArray />
        </div>
    )
}

export default TournamentGrouping