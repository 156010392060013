import axios from 'axios'

const DOMAIN = 'https://us-central1-teeboxfantasy.cloudfunctions.net'

async function getAllPlayers() {
    const response = await axios.get(`${DOMAIN}/getPlayers`)
    return response.data.players
}

async function getAllTournaments() {
    const response = await axios.get(`${DOMAIN}/getTournaments`)
    return response.data.tourniesPGA
}

// async function createBaseGame(tournamentID) {
//     const response = await axios.get(`${DOMAIN}/createFirebaseEvent?tournamentID=${tournamentID}`)
//     return response.data
// }

async function getUserEmailMapping(competition_id) {
    const response = await axios.get(`${DOMAIN}/getCompetitionUsersInfo?competition_id=${competition_id}`)
    return response.data
}

async function getPlayerScores(date_id, handleError) {
    const response = await axios.get(`${DOMAIN}/getPlayerScores?date_id=${date_id}`).catch(handleError)
    return response.data
}

export { getAllPlayers, getAllTournaments, getPlayerScores, getUserEmailMapping };