import { where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getActiveEventID, useSession } from "../firebase/firebase"
import useCompetitions from "../firebase/useCompetitions"
import useEvent from "../firebase/useEvent"
import TournamentCard from "./TournamentCard"
import { CircularProgress } from "react-loading-indicators"
import LoadingComponent from "./LoadingComponent"
import SectionHeader from "./SectionHeader"
import CompletedContestCard from "./CompletedContestCard"

function Dashboard() {

    const user = useSession()
    const [loading, setLoading] = useState(true)
    const [activeEventID, setActiveEventID] = useState(null)
    const eventLoader = useEvent(activeEventID)
    const myCompetitionsLoader = useCompetitions(user?.uid, [where('participantids', 'array-contains', user?.uid)])
    
    const globalCompetitionsLoader = useCompetitions(activeEventID, [where('global', "==", true), where('date_id', "==", activeEventID)])

    useEffect(() => {
        async function loadActiveEventID() {
            setLoading(true)
            console.log('lodaing active event')
            const eventid = await getActiveEventID()
            console.log('active event id: ', eventid)
            setActiveEventID(eventid)
            setLoading(false)
            return
        }
        loadActiveEventID()
    }, [])

    function PreviousCompetitions() {
        if (myCompetitionsLoader.competitions.length === 0) {
            return <></>
        } else {
            const cevs = myCompetitionsLoader.competitionEvents().filter(c => { return c.competition.date_id !== activeEventID })
            return (
                <div className="mt-4 flex flex-col space-y-3">
                    <SectionHeader text={"My Completed Contests"} />
                    {
                        cevs.map((c, idx) => {
                            return <CompletedContestCard key={idx} competitionEventInfo={c} />
                        })
                    }
                </div>
            )
        }
    }

    function aggregatedCompetitions() {
        const thisEventMine = []
        myCompetitionsLoader.competitions.forEach((c) => {
            if (c.date_id === eventLoader.event?.id) {
                thisEventMine.push(c)
            }
        })
        // console.log('aggregated comps... thiseventmine: ', thisEventMine.length, " | global: ", globalCompetitionsLoader.competitions.length)

        globalCompetitionsLoader.competitions.forEach((c) => {
            if (!thisEventMine.find(e => c.id === e.id)) {
                thisEventMine.push(c)
            }
        })
        // return [].concat(thisEventMine, globalCompetitionsLoader.competitions)
        return thisEventMine
    }

    if (loading || eventLoader.loading || myCompetitionsLoader.loading || globalCompetitionsLoader.loading) {
        console.log('loading: ', loading)
        console.log('eventloader  loading: ', eventLoader.loading)
        console.log('mycompsloader loading: ', myCompetitionsLoader.loading)
        console.log('global loading: ', globalCompetitionsLoader.loading)
        return (<LoadingComponent />)
    } else if (!activeEventID) {
        return (<div>no active tourny</div>)
    } else {
        return (
            <div className="w-full flex flex-col items-center bg-gray-100">
                <div className="w-full max-w-xl">
                    <div className="flex-col space-y-2 m-2">
                        <SectionHeader text={"This Week's PGA TOUR Event"} />
                        <TournamentCard eventLoader={eventLoader} competitions={aggregatedCompetitions()} />
                        <PreviousCompetitions />
                    </div>
                </div>
            </div>
        )
    }

}

export default Dashboard