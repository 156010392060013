import { Link } from "react-router-dom"
import arrow from "../assets/fwd_crt_white.png"

function TournamentCard({ eventLoader, competitions }) {

    // console.log('heroimage: ', eventLoader.tournament?.heroImage)

    return (
        <div className="shadow-md h-fit w-full rounded-md bg-white flex flex-col space-y-2">
            <div className={`h-40 w-full bg-center bg-no-repeat bg-cover rounded-t-md`} style={{ backgroundImage: `url(${eventLoader.event?.heroImage})` }}>
                <div className="w-full h-full bg-black/30 backdrop-brightness-75 flex flex-col justify-between text-white pt-3 pb-3 pl-4 pr-4 rounded-t-md">
                    <div className="font-bold text-xl">{eventLoader.event?.name}</div>
                    <div className="font-semibold text-sm">{eventLoader.event?.getDateRangeText()}</div>
                </div>
            </div>
            <div className="m-2 flex flex-col space-y-2 pb-2">
                {
                    competitions.map((c, idx) => {
                        return (<Link key={c.id} className="w-full flex" to={`/tournaments/${c.date_id}/competitions/${c.id}`}>
                            <button className="w-full bg-darkBlue hover:bg-gray-400 font-bold text-white text-left rounded-md px-4 py-2 flex flex-row justify-between items-center">
                                {c.name}
                                <img className="h-4" src={arrow} />
                            </button>
                        </Link>)
                    })
                }
                <Link className={`w-full flex ${ eventLoader.tournyStillUpcoming() ? '':'hidden' } `} to={`/tournaments/${eventLoader.event?.id}/create`}>
                    <button className="w-full bg-darkGreen hover:bg-gray-400 px-4 py-2 rounded-md text-white text-left font-bold flex flex-row justify-between items-center">
                        {"Create New Contest"}
                        <img className="h-4" src={arrow} />
                    </button>
                </Link>
                <label className="ml-1 flex align-center text-sm font-medium">Looking for your friend's contest? Ask them to send the invite link</label>
            </div>

        </div>
    )
}

export default TournamentCard