import { getPlayerScores } from "../data/api";
// const { doc, onSnapshot } = require("firebase/firestore");
import { onSnapshot } from "firebase/firestore";
const { useEffect, useState } = require("react");
const { getEventDocRef } = require("./firebase");

function useEvent(date_id) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState(null)

    // from api
    const [playerScores, setPlayerScores] = useState({})
    const [eventStatusName, setEventStatusName] = useState("")
    const [eventStatusText, setEventStatusText] = useState("")

    useEffect(() => {
        if (!date_id) { return }
        console.log('event date_id: ', date_id)
        const eventDocRef = getEventDocRef(date_id)
        const unsubscribe = onSnapshot(eventDocRef, (d) => {
            if (d.exists()) {
                const e = d.data()
                e.id = d.id
                setEvent(e)
                fetchScores(e)
            } else {
                setEvent(null)
                fetchScores()
            }
            // fetchScores()
        })
        return () => unsubscribe()
    }, [date_id])

    async function fetchScores(e) {
        console.log('event: ', e)
        if (date_id === "-") { return }
        setLoading(true)
        // const response = await getPlayerScores(date_id, handleFetchScoresError)
        let response
        if (e?.currentJson) {
            console.log('setting from currentjson')
            response = e?.currentJson
            // response = JSON.parse(e?.currentJson)
        } else {
            console.log('loading remotely')
            response = await getPlayerScores(date_id, handleFetchScoresError)
        }
        
        // console.log('response: ', response)
        setPlayerScores(response.scores)
        setEventStatusName(response.statusName)
        setEventStatusText(response.statusText)
        setLoading(false)
        return
    }

    function handleFetchScoresError(err) {
        setPlayerScores({})
        setEventStatusName("")
        setEventStatusText("")
        setLoading(false)
    }

    function tournyStillUpcoming() {
        return eventStatusName === 'STATUS_SCHEDULED'
    }

    function roundInProgress() {
        return eventStatusName === 'STATUS_IN_PROGRESS' || eventStatusName === 'STATUS_DELAYED'
    }

    return {
        error,
        loading,
        event,
        playerScores,
        eventStatusName,
        eventStatusText,
        fetchScores,
        tournyStillUpcoming,
        roundInProgress
    }
}

export default useEvent