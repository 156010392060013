// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
import { collection, doc, getFirestore, addDoc, updateDoc, query, getDocs, where } from "firebase/firestore";
import { useState, useEffect, createContext, useContext } from "react";
import { eventConverter } from "../data/Event";
import { Competition, competitionConverter } from "../data/Competition";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBEuLe3HAixmeegmIMUckvDKC2-dk63d5I",
  authDomain: "teeboxfantasy.firebaseapp.com",
  projectId: "teeboxfantasy",
  storageBucket: "teeboxfantasy.appspot.com",
  messagingSenderId: "579884971106",
  appId: "1:579884971106:web:b11e6ff8cfa3ff31e58d3b",
  measurementId: "G-8GRJSB5MM3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

// collections
export const EVENTS = "events"
export const eventsCollection = collection(db, EVENTS).withConverter(eventConverter)
export const COMPETITIONS = "competitions"
export const competitionsCollection = collection(db, COMPETITIONS).withConverter(competitionConverter)

// authentication
export const userContext = createContext({ user: null })
export const useSession = () => {
  const { user } = useContext(userContext)
  return user
}
export const useAuth = () => {
  const [state, setState] = useState(() => {
    const user = auth.currentUser
    return {
      initializing: !user,
      user
    }
  })

  function onChange(user) {
    setState({ initializing: false, user })
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(onChange)
    return () => unsubscribe()
  }, [])

  return state
}

export const createAccount = async (email, password, completion) => {
  console.log(`create acct with email: ${email}, pw: ${password}`)
  createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
    console.log('successfully created account...')
    logFirebaseEvent('sign_up', { provider: 'email' })
    if (completion) {
      completion()
    }
  }).catch((error) => {
    alert(error.message)
  })
}

export const logout = async () => {
  logFirebaseEvent('log_out', null)
  await auth.signOut()
}

export const logIn = async (email, password, completion) => {
  signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    console.log("successful login...")
    logFirebaseEvent('log_in', { provider: 'email' })
    if (completion) {
      completion()
    }
  }).catch((error) => {
    alert(error.message)
  })
}

export const connectWithGoogle = async (completion) => {
  const googleProvider = new GoogleAuthProvider()
  const auth = getAuth();
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      const details = getAdditionalUserInfo(result)
      if (details.isNewUser) {
        logFirebaseEvent('sign_up', { provider: 'google' })
      } else {
        logFirebaseEvent('log_in', { provider: 'google' })
      }
      if (completion) {
        completion()
      }
    }).catch((error) => {
      alert(error.message)
    })
}

export const logFirebaseEvent = async (name, paramsDict) => {
  logEvent(analytics, name, paramsDict)
}

export const getEventDocRef = (id) => {
  // return doc(db, EVENTS, id)
  return doc(db, EVENTS, id).withConverter(eventConverter)
}

export const getActiveEventID = async () => {
  const q = query(eventsCollection, where("active", "==", true))
  const querySnapshot = await getDocs(q)
  if (querySnapshot.docs.length > 0) {
    // const doc = querySnapshot.docs[0]
    // const docData = doc.data()
    return querySnapshot.docs[0].id
    // return { date_id: doc.id, espn_id: docData?.espn_id }
  } else {
    // return { date_id: "-", espn_id: null }
    return "-"
  }
}

export const getCompetitionDocRef = (id) => {
  return doc(db, COMPETITIONS, id).withConverter(competitionConverter)
}

export const createCompetition = async (name, date_id, createdBy) => {
  const now = new Date()
  const newCompetition = new Competition(name, date_id, now, createdBy, [], false)
  const resultRef = await addDoc(competitionsCollection, newCompetition)
  return resultRef.id
}

export const updateCompetition = async (competition) => {
  const ref = getCompetitionDocRef(competition.id)
  const res = await updateDoc(ref, competitionConverter.toFirestore(competition))
  return res
}